import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { getArticlePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import PageHero from "./pageHero"
import ContactForm from "../components/blocks/contactForm"
import { Container, Grid, Text, Box } from "theme-ui"
import { renderRule, StructuredText } from "react-datocms"
import { isHeading, isParagraph } from "datocms-structured-text-utils"

const Article = ({ data: { site, article } }) => {
  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getArticlePath(article),
    }
  })

  const ldJson = {
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    headline: article.seo.title || article.title,
    author: {
      "@type": "Organization",
      name: "Socaf S.p.a.",
      logo: {
        "@type": "ImageObject",
        url: "https://www.caldofacile.it/static/logo-caldofacile-95216498d0abb68ebbddfcdf70745e63.svg",
      },
    },
    image: {
      "@type": "ImageObject",
      url: article.heroImage.gatsbyImageData.images.fallback.src,
    },
    datePublished: article.meta.firstPublishedAtIso,
    publisher: {
      "@type": "Organization",
      name: "Socaf S.p.a.",
      logo: {
        "@type": "ImageObject",
        url: "https://www.caldofacile.it/static/logo-caldofacile-95216498d0abb68ebbddfcdf70745e63.svg",
      },
    },
    dateModified: article.meta.updatedAt,
    description: article.seo.description,
    url: `https://www.caldofacile.it${getArticlePath(article, article.locale)}`,
    identifier: `https://www.caldofacile.it${getArticlePath(
      article,
      article.locale
    )}`,
  }

  return (
    <Layout locale={article.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={article.seoMetaTags}>
        <html lang={article.locale} />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </HelmetDatoCms>
      <PageHero page={article} image={article.heroImage} />
      <Container>
        <Grid columns={[1, 1, "1fr 5fr", "1fr 5fr 1fr"]} gap={32}>
          <Box>
            <Text
              as="p"
              sx={{ color: "textGrey", fontSize: 1, mt: 1, mb: [1, 1, 4] }}
            >
              {article.meta.firstPublishedAt}
            </Text>
          </Box>
          <Box>
            <StructuredText
              data={article.body}
              customRules={[
                renderRule(
                  isHeading,
                  ({ adapter: { renderNode }, node, children, key }) => {
                    return renderNode(
                      () => {
                        return (
                          <Text
                            as={`h${node.level}`}
                            variant={`h${node.level}`}
                            sx={{ mb: 3 }}
                          >
                            {children}
                          </Text>
                        )
                      },
                      { key },
                      children
                    )
                  }
                ),
                renderRule(
                  isParagraph,
                  ({ adapter: { renderNode }, children, key }) => {
                    return renderNode(
                      () => {
                        return (
                          <Text as="p" mb={3} variant="article">
                            {children}
                          </Text>
                        )
                      },
                      { key },
                      children
                    )
                  }
                ),
              ]}
            />
          </Box>
          <Box></Box>
        </Grid>
        {article.content.map(
          block =>
            block.model &&
            block.model.apiKey && (
              <Box as="section" key={block.id}>
                {block.model.apiKey === "contact_form" && (
                  <ContactForm
                    title={block.title}
                    subtitle={block.subtitle}
                    description={block.description}
                  />
                )}
              </Box>
            )
        )}
      </Container>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ArticleQuery($id: String!) {
    site: datoCmsSite {
      locales
    }
    article: datoCmsArticle(id: { eq: $id }) {
      id
      title
      locale
      slug
      heroImage {
        gatsbyImageData(
          width: 1920
          placeholder: NONE
          forceBlurhash: false
          imgixParams: { blendColor: "EA0029", blendMode: "hardlight", blendAlpha: 90 }
        )
      }
      meta {
        firstPublishedAt(formatString: "dddd D MMM YYYY", locale: "it")
        firstPublishedAtIso: firstPublishedAt
        updatedAt
      }
      body {
        value
      }
      _allSlugLocales {
        value
        locale
      }
      seo {
        title
        description
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsContactForm {
          id
          title
          subtitle
          description
          model {
            apiKey
          }
        }
      }
    }
  }
`
