import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Container, Heading } from "theme-ui"
import Breadcrumbs from "../components/breadcrumbs"

const PageHero = ({ page, image }) => (
  <Box
    sx={{
      position: "relative",
      backgroundColor: "primary",
      mb: 0,
      height: ["300px", "500px"],
      zIndex: "1",
      mt: [0, 0, 0, "45px"],
    }}
  >
    {image && (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: ["100%"],
          width: "100%",
          ".gatsby-image-wrapper": {
            height: ["100%"],
            width: "100%",
          },
          overflow: "hidden",
        }}
      >
        <GatsbyImage image={image.gatsbyImageData} alt="" />
      </Box>
    )}
    <Container
      sx={{
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Heading
        as="h1"
        variant="h2"
        sx={{ mt: 0, fontSize: [6, 6] }}
        color="light"
      >
        {page.title}
      </Heading>
      <Box sx={{ display: ["none", "block", "block", "block"] }}>
        <Breadcrumbs page={page} />
      </Box>
    </Container>
  </Box>
)

export default PageHero
